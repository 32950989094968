import React, { useRef, useEffect, useState, useContext } from "react"
import { useNavigate, Link } from "react-router-dom"
import { useQuery, useLazyQuery, useMutation, gql } from "@apollo/client"
import { LoaderMedium } from "../../components/Loaders"
import ROUTES from "../../components/Routes/routesConstants"
import { UserContext } from "../../context/userContext"
import PropTypes from "prop-types"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { SplitButton } from "primereact/splitbutton"
import { Toast } from "primereact/toast"
import { Button } from "primereact/button"
import { ConfirmDialog } from "primereact/confirmdialog"

//GA
import gATracking from "../../helpers/gaTracking"
import getActualTime from "../../helpers/getActualTime"
const GET_PARTICIPANTS = gql`
  query Participants {
    participants: participantsActive {
      participant_id
      participant_name_full
      participant_email
      participant_sms_phone
      staff_name_full
      organization_cohort_name
      organization_name
      organization_id
      date_created
      participant_lesson_log {
        course_id
        course_name
        course_lesson_id
        course_lesson_name
        course_lesson_complete
        date_created
      }
    }
  }
`

const GET_LESSONS_PER_COURSE = gql`
  query GetCourse($id: ID!) {
    course(id: $id) {
      id
      lessons {
        id
      }
    }
  }
`

const DELETE_PARTICIPANTS = gql`
  mutation deleteParticipant($participant_id: ID!) {
    deleteParticipant(participant_id: $participant_id) {
      message
    }
  }
`

const UNASSIGN_PARTICIPANTS = gql`
  mutation unassignParticipant($participant_id: ID!) {
    unassignParticipant(participant_id: $participant_id) {
      data
      message
      error
    }
  }
`

const CourseStatus = ({ courseId, completedCourses }) => {
  const [
    getCourse,
    { data: courseData, loading: courseLoading, error: courseError },
  ] = useLazyQuery(GET_LESSONS_PER_COURSE)

  useEffect(() => {
    if (courseId) {
      getCourse({ variables: { id: courseId } })
    }
  }, [courseId, getCourse])

  let progress = "Not Started"
  if (courseData) {
    const totalLessons = courseData.course.lessons.length

    if (completedCourses !== 0) {
      if (totalLessons === completedCourses) {
        progress = "Completed"
      } else {
        progress = "In Progress"
      }
    }
  }
  return (
    <div>
      {courseLoading && <p>Loading...</p>}
      {courseError && <p>Error: {courseError.message}</p>}
      {courseData && <p>{progress}</p>}
    </div>
  )
}

CourseStatus.propTypes = {
  courseId: PropTypes.string.isRequired,
  completedCourses: PropTypes.number.isRequired,
}

export default function ParticipantList() {
  const userCtx = useContext(UserContext)
  const date = getActualTime()
  const [showDialog, setShowDialog] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState(null)

  const { loading, error, data } = useQuery(GET_PARTICIPANTS, {
    fetchPolicy: "network-only",
  })

  const [deleteParticipant, { data: mutationData, error: mutationError }] =
    useMutation(DELETE_PARTICIPANTS)

  const [unassignParticipant, { data: unassignData, error: unassignError }] =
    useMutation(UNASSIGN_PARTICIPANTS)

  const navigate = useNavigate()
  const toast = useRef(null)

  useEffect(() => {
    if (mutationData?.deleteParticipant?.message) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: mutationData.deleteParticipant.message,
        life: 3000,
      })
    } else if (mutationError) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: mutationError.message,
        life: 3000,
      })
    }
  }, [mutationData, mutationError])

  useEffect(() => {
    if (unassignData?.unassignParticipant?.message) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: unassignData.unassignParticipant.message,
        life: 3000,
      })
    } else if (unassignError) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: unassignError.message,
        life: 3000,
      })
    }
  }, [unassignData, unassignError])

  if (loading) return <LoaderMedium />
  if (error) return <div>Error! {error.message}</div>

  const participants = data.participants || []

  /*Google Analytics tracking*/
  const gaTrackingParams = {
    event: "button_click",
    userCtx,
    payload: {},
  }
  /*End Google Analytics tracking*/

  const handleAssign = (rowData) => {
    setSelectedParticipant({
      participant_id: rowData.participant_id,
      staff_name_full: rowData.staff_name_full,
      participant_name_full: rowData.participant_name_full,
    })
    setShowDialog(true)
  }

  const onConfirm = () => {
    const { participant_id } = selectedParticipant

    unassignParticipant({
      variables: { participant_id },
      refetchQueries: [{ query: GET_PARTICIPANTS }],
    })
  }

  const onCancel = () => {
    setShowDialog(false)
  }

  const action = (rowData) => {
    const { participant_id } = rowData
    const items = [
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          gaTrackingParams.event = "deleted"
          gaTrackingParams.payload = {
            organization_id: rowData.organization_id,
            content_id: participant_id,
            content_type: "participant",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          deleteParticipant({
            variables: { participant_id },
            refetchQueries: [{ query: GET_PARTICIPANTS }],
          })
        },
      },
      {
        label: "Unassign",
        icon: "pi pi-times-circle",
        command: () => {
          handleAssign(rowData)
        },
      },
    ]
    return (
      <SplitButton
        label="Edit"
        model={items}
        onClick={() => {
          gaTrackingParams.event = "edit"
          gaTrackingParams.payload = {
            organization_id: rowData.organization_id,
            content_id: participant_id,
            content_type: "participant",
            timestamp: date,
          }
          gATracking(gaTrackingParams)

          navigate(`/form/edit/profile/1/${participant_id}`)
        }}
      />
    )
  }

  const nameTemplate = (rowData) => {
    return (
      <Link
        style={{
          textDecoration: "underline",
          color: "var(--branded-light-green)",
          cursor: "pointer",
        }}
        onClick={() => {
          gaTrackingParams.payload = {
            button_name: "click_view_name",
            button_text: rowData.participant_name_full,
            button_type: "link_click",
            button_location: "/list/participant",
          }
          gATracking(gaTrackingParams)
        }}
        to={`/participant/profile/${rowData.participant_id}`}
      >
        {rowData.participant_name_full}
      </Link>
    )
  }

  const courseStatusTemplate = (rowData) => {
    if (rowData.participant_lesson_log?.[0]?.course_id) {
      const courseId = rowData.participant_lesson_log?.[0]?.course_id
      const courseLenght = rowData.participant_lesson_log.length
      return (
        <CourseStatus courseId={courseId} completedCourses={courseLenght} />
      )
    }
    return <p> Not Started </p>
  }

  return (
    <div className="p-col-12 p-md-3">
      <div className="flex align-items-center justify-content-between my-3">
        <h1>Coaching Assigments</h1>

        <Button
          className="mb-2"
          label="View All Participants"
          style={{ backgroundColor: "var(--branded-dark-blue)" }}
          onClick={() => {
            navigate(`../../${ROUTES.PARTICIPANT_LIST_ALL}`)
          }}
        />
      </div>
      <Toast ref={toast} />
      <DataTable
        paginator
        rows={10}
        columnResizeMode="fit"
        value={participants}
        resizableColumns
        showGridlines
        scrollHeight="flex"
        className="w-full"
        style={{ tableLayout: "fixed", maxWidth: "100%" }}
      >
        <Column
          sortable
          filter
          field="organization_name"
          header="Organization"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="organization_cohort_name"
          header="Cohort"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />

        <Column
          sortable
          filter
          field="participant_name_full"
          header="Name"
          body={nameTemplate}
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          filter
          field="participant_email"
          header="Email"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "175px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="participant_sms_phone"
          header="Phone"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
        />
        <Column
          sortable
          field="staff_name_full"
          header="Assigned Coach"
          className="white-space-normal	overflow-hidden	"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
        />

        <Column
          sortable
          field="organization_cohort_name"
          header="Course Status"
          headerStyle={{ whiteSpace: "pre-line", textAlign: "center" }}
          body={courseStatusTemplate}
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "150px", wordWrap: "break-word" }}
        />
        <Column
          frozen
          body={(rowData) => action(rowData)}
          header="Action"
          className="white-space-normal overflow-hidden"
          style={{ maxWidth: "200px", wordWrap: "break-word" }}
        />
      </DataTable>

      <ConfirmDialog
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        message={`Are you sure you want to unassign the coach: ${selectedParticipant?.staff_name_full} from ${selectedParticipant?.participant_name_full}?`}
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={onConfirm}
        reject={onCancel}
      />
    </div>
  )
}
